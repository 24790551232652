import { FuncionesValoraciones } from 'app/componentes/envios/Valoraciones/FuncionesValoraciones';
import TienePermisos from 'app/helpers/TienePermisos';
import { FicheroLecturasFactory } from 'app/helpers/classes/FicheroLecturas/FicheroLecturasFactory.class';
import { calcularComisionDesdePorcentaje, setObjetoParaObtenerDatosComisiones } from 'app/helpers/comisiones';
import { base64ToBlob, ejecutaAPI, sumaColumnaArray } from 'app/helpers/libFunciones';
import { envios as permisosEnvios, recogidas, usuarios } from 'app/helpers/permisos';
import { calculaTotalValoraciones } from 'app/helpers/valoraciones';
import { getDepartamentosCliente } from 'app/hooks/api/departamentos/useApiDepartamentosCliente';
import { useApiModificacionParcialEnvio } from 'app/hooks/api/envios/useApiModificacionParcialEnvio';
import { getRedes } from 'app/hooks/api/redes/useListadoRedes';
import { useApiGenerarXML } from 'app/hooks/api/trafico/useApiGenerarXML';
import { getDatosComisiones } from 'app/hooks/api/trafico/useDatosComisiones';
import { useRecalcularComisiones } from 'app/hooks/api/trafico/useRecalcularComisiones';
import { useGlobalLoader, useRoles, useUsuarioConectado } from 'app/hooks/hooksPropios';
import { swalError } from 'app/librerias/swal';
import { EnvioParcialMapper, transformaDatosParcialesEnvioParaTrafico } from 'app/modules/trafico/helpers/trafico.mapper';
import { toPairs } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import { useDelta, useDeltaObject } from 'react-delta';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { SelectDelegacionesRed, SelectEstadosTrafico, SelectMensajeros, SelectPaises, SelectProvincias, SelectServiciosRed_Cache, SelectServiciosTipo, SelectTiposIVA } from '../../../../helpers/selects';
import { agrupaClientes, agrupaDelegaciones, compruebaTraficoRedux, filtraTrafico, filtrosSQL, ordenaTrafico, usaFiltrosAvanzados } from '../../../../modules/trafico/helpers/trafico.helper';


export const ListadoTraficoContext = React.createContext();

const traficoReducer = (state, action) => {

    switch (action.type) {
        case 'CARGANDO_INIT':
            return { ...state, cargando: true };

        case 'CARGANDO_END':
            return { ...state, cargando: false };

        case 'SET_INIT':
            return { ...state, init: action.payload };

        case 'LISTADO_SUCCESS':

            var arrayFiltrado = filtraTrafico(action.payload, state.filtros);
            arrayFiltrado = ordenaTrafico(arrayFiltrado,state.filtros);

            var arrayFiltradoClientes = agrupaClientes(arrayFiltrado);
            var arrayFiltradoDelegaciones = agrupaDelegaciones(arrayFiltrado);

            
            var accionMasivaSel = (arrayFiltrado.length > 0) ? state.accionMasivaSobre : "";
            var accionSel = (arrayFiltrado.length > 0) ? state.accionSeleccionada : "";
            
            return { ...state, listadoAPI: action.payload, listadoMostrar: arrayFiltrado, listadoMostrarAgrupadoClientes: arrayFiltradoClientes, listadoMostrarAgrupadoDelegaciones: arrayFiltradoDelegaciones, registrosSeleccionados: [], recargar: false, accionMasivaSobre: accionMasivaSel, accionSeleccionada: accionSel };

        case 'LISTADO_ERROR':
            return { ...state, listadoAPI: [], listadoMostrar: [], recargar: false };
        case 'SET_MENSAJEROS':

            var mensajeros = action.payload.map((mensajero) => {
                mensajero.nombreCompleto = `${mensajero.nombre} ${mensajero.apellidos}`;
                return mensajero;
            })

            return { ...state, mensajeros: mensajeros };
        case 'SET_REDES':
            return { ...state, redes: action.payload };
        case 'SET_CLIENTES':
            return { ...state, clientes: action.payload };
        case 'SET_DELEGACIONES':

            var nuevasDelegaciones = [...state.delegaciones, ...action.payload];

            var delegacionesRed = nuevasDelegaciones.filter(delegacion => delegacion.idRed === state.filtros.idRed);


            return { ...state, delegaciones: nuevasDelegaciones, delegacionesRed: delegacionesRed }
        case 'SET_SERVICIOS':

            var nuevosServicios = [...state.servicios, ...action.payload];

            var serviciosRed = nuevosServicios.filter(servicio => servicio.idRed === state.filtros.idRed);

            return { ...state, servicios: nuevosServicios, serviciosRed: serviciosRed }
        case 'SET_SERVICIOS_TIPOS':
            return { ...state, serviciosTipos: action.payload };
        case 'SET_PAISES':
            return { ...state, paises: action.payload };
        case 'SET_PROVINCIAS':
            return { ...state, provincias: action.payload };
        case 'SET_ESTADOS':

            var arrayEstadoRecogidas = [];

            var arrayEstadoEnvios = [];

            action.payload.forEach((estado) => {

                if (estado.tablaOrigen === "recogidas") 
                {
                    arrayEstadoRecogidas.push(estado);
                }
                else 
                {
                    arrayEstadoEnvios.push(estado);
                }

            });

            return { ...state, estados: action.payload, estadosRecogida: arrayEstadoRecogidas, estadosEnvio: arrayEstadoEnvios }
        case 'REMOVE_MENSAJEROS':
            return { ...state, mensajeros: [] };
        case 'RECARGAR_LISTADO':
            return { ...state, recargar: action.payload }
        case 'SET_FILTROS':


            arrayFiltrado = filtraTrafico(state.listadoAPI, action.payload);
            arrayFiltrado = ordenaTrafico(arrayFiltrado, action.payload);

            arrayFiltradoClientes = agrupaClientes(arrayFiltrado);
            arrayFiltradoDelegaciones = agrupaDelegaciones(arrayFiltrado);

            const newFiltros = { ...state.filtros, ...action.payload };
            localStorage.setItem("filtrosTrafico", JSON.stringify(newFiltros));
            localStorage.setItem("fecha_filtrosTrafico", moment().format("YYYY-MM-DD"));

             accionMasivaSel=(arrayFiltrado.length>0)?state.accionMasivaSobre:"";
             accionSel = (arrayFiltrado.length > 0) ? state.accionSeleccionada : "";
            
            return { ...state, init: 2, filtros: newFiltros, listadoMostrar: arrayFiltrado, listadoMostrarAgrupadoClientes: arrayFiltradoClientes, listadoMostrarAgrupadoDelegaciones: arrayFiltradoDelegaciones,accionMasivaSobre:accionMasivaSel,accionSeleccionada:accionSel }

        case 'SET_ACCION_MASIVA_SOBRE':
            return { ...state, accionMasivaSobre: action.payload, registrosSeleccionados: [], accionSeleccionada: "" }
        case 'HANDLE_ELEMENTOS_SELECCIONADOS':

            var seleccionados = [...state.registrosSeleccionados];

            if (action.payload.checked) 
            {
                seleccionados.push(action.payload.id);
            }
            else 
            {
                seleccionados = seleccionados.filter(item => item !== action.payload.id);
            }

            return { ...state, registrosSeleccionados: seleccionados }
        case 'HANDLE_SELECCIONAR_TODOS_ELEMENTOS':

            var elementos = [...state.registrosSeleccionados];

            var visibles = state.listadoMostrar.filter(elemento => elemento.tipo === state.accionMasivaSobre);

            if (elementos.length === visibles.length) {
                elementos = [];
            }
            else {
                elementos = visibles.map(elemento => elemento.id);
            }

            return { ...state, registrosSeleccionados: elementos }
        case 'SET_ACCION_SELECCIONADA':
            return { ...state, accionSeleccionada: action.payload }
        case 'SET_DEPARTAMENTOS_FILTRO':

            var nuevosDepartamentos = [...state.departamentos, ...action.payload];

            var departamentosSeleccionados = nuevosDepartamentos.filter(departamento => departamento.idCliente === state.filtros.idCliente);

            return { ...state, departamentos: nuevosDepartamentos, departamentosCliente: departamentosSeleccionados }
        case 'SET_DEPARTAMENTOS_CLIENTE':
            return { ...state, departamentosCliente: action.payload }
        case 'CHANGE_DESPLEGAR_FILTROS':
            return { ...state, filtrosDesplegados: !state.filtrosDesplegados }
        case 'SET_URL_ETIQUETAS':
            return { ...state, urlEtiquetas: action.payload }
        case 'SET_VISTA':
            return { ...state, filtros: { ...state.filtros, vista: action.payload } }
        case 'SET_IMPUESTOS':
            return { ...state, impuestos: action.payload }
        case 'SET_PAGINA':
            return { ...state, pag: action.payload }

         //Cambia en la visualizacoin el mensajero sin recargar el listado completo   
        case 'RECARGAR_MENSAJERO':

            var arrs=["listadoMostrar","listadoAPI"];

            arrs.forEach(item_arr=>{                

                  arrs[item_arr]=state[item_arr] && state[item_arr].map(item => {
                    if (item.idUnico == action.payload.idUnico) {
                        return { ...item, idMensajero: action.payload.idMensajero };
                    } else {
                        return item;
                    }
                });

            })

            return { ...state, listadoMostrar:arrs["listadoMostrar"],listadoAPI:arrs["listadoAPI"] }; 

        case 'RECARGAR_ESTADO':

            arrs = ["listadoMostrar", "listadoAPI"];

            arrs.forEach(item_arr => {

                arrs[item_arr] = state[item_arr] && state[item_arr].map(item => {
                    if (item.idUnico == action.payload.idUnico) {
                        return { ...item, estado: action.payload.estado };
                    } else {
                        return item;
                    }
                });

            })

            return { ...state, listadoMostrar: arrs["listadoMostrar"], listadoAPI: arrs["listadoAPI"] }; 
        case 'ACTUALIZAR_ESTADO_TRAFICO':

            const newListadoApi = state.listadoAPI.map(item => item.id == action.payload.id && item.tipo === "envio" ? {...item, ...action.payload.actualizar} : item);

            const newListadoMostrar = state.listadoMostrar.map(item => item.id == action.payload.id && item.tipo === "envio" ? {...item, ...action.payload.actualizar} : item);

            return { ...state, listadoMostrar: newListadoMostrar, listadoAPI: newListadoApi };
        default:
            throw new Error();
    }
}


export const ListadoTraficoProvider = ({ children }) => {

    const vista_defecto = (localStorage.getItem("vistaTrafico") != null ? localStorage.getItem("vistaTrafico") : "recogidaEnvios");

    const { datosUser } = useUsuarioConectado();

    const { esMensajero } = useRoles();


    const filtrosInit = {
        fecha_opcion:"hoy",
        fechaDesde: moment().format("DD-MM-YYYY"),
        fechaHasta: moment().format("DD-MM-YYYY"),
        distribucionPropia: '',
        separadorBuscador: '',
        buscador: '',
        tipo: '',
        estadoRecogida: [],
        estadoEnvio: [],
        idMensajero: (esMensajero && !TienePermisos([permisosEnvios.crear])) ? datosUser.user.id : '',
        idComercial: '',
        idRed: '',
        idDelegacion: '',
        idServicio: '',
        idServicioTipo: '',
        portes: '',
        idPais: '',
        pesoDesde: '',
        pesoHasta: '',
        bultosDesde: '',
        bultosHasta: '',
        precioDesde: '',
        precioHasta:'',
        beneficioDesde:'',
        beneficioHasta:'',
        tipoBeneficio:'',
        idCliente: '',
        idDepartamento: '',
        anulado: '0',
        transmitido: '',
        categorias: '',
        vista: vista_defecto,
        valorado: '',
        importado: '',
        ficherosRecogida: '',
        numRecogida:'',
        facturado: '',
        contraReembolso:'',
        fechaReembDesde: moment().format("DD-MM-YYYY"),
        fechaReembHasta: moment().format("DD-MM-YYYY"),
        cpDesde: '',
        cpHasta: '',
        variables: [],
        costesReales: '',
        emailEnvioTransitoEnviado: '',
        orden:'fecha',
    };

    
    const traficoInit = {

        listadoAPI: [],
        listadoMostrar: [],
        listadoMostrarAgrupadoClientes: [],
        listadoMostrarAgrupadoDelegaciones: [],
        mensajeros: [],
        redes: [],
        clientes: [],
        serviciosTipos: [],
        estados: [],
        delegaciones: [],
        delegacionesRed: [],
        servicios: [],
        serviciosRed: [],
        estadosRecogida: [],
        estadosEnvio: [],
        registrosSeleccionados: [],
        paises: [],
        provincias: [],
        filtros: (localStorage.getItem("filtrosTrafico") != null && localStorage.getItem("fecha_filtrosTrafico") == moment().format("YYYY-MM-DD")) ? JSON.parse(localStorage.getItem("filtrosTrafico")) : filtrosInit,
        filtrosInit: { ...filtrosInit, vista: vista_defecto },
        filtrosDesplegados: usaFiltrosAvanzados(),
        accionMasivaSobre: "",
        accionSeleccionada: "",
        departamentos: [],
        departamentosCliente: [],
        urlEtiquetas: "",
        cargando: false,
        error: false,
        recargar: false,
        init: 0,
        impuestos: [],
        pag: 1

    }

    

    const traficoRedux = useSelector((state) => state.trafico);

    var estado_trafico = traficoInit;
    
    if (compruebaTraficoRedux(traficoRedux))
    {
        estado_trafico=traficoRedux.traficoObj;
    }

    const [trafico, dispatch] = useReducer(traficoReducer, estado_trafico);

    return (
        <ListadoTraficoContext.Provider value={[trafico, dispatch]}>
            {children}
        </ListadoTraficoContext.Provider>
    )
}



export const useTrafico = (filtroTrafico) => {

    const [trafico, dispatch] = useContext(ListadoTraficoContext);

    const { esRolAdministrativo, esCliente, esMensajero } = useRoles();

    const { idCliente } = useUsuarioConectado();

    const { setGlobalCargando } = useGlobalLoader();

    const refIdRed = useDelta(trafico.filtros.idRed);

    const refIdClienteFiltro = useDelta(trafico.filtros.idCliente);
    
    const auxRecargar = [];

    filtrosSQL.forEach(item => {
        auxRecargar[item] = trafico.filtros[item];
    });

    if (esMensajero)
    {
        auxRecargar["idMensajero"] = trafico.filtros["idMensajero"];
    }

    const refFiltrosRecargar = useDeltaObject(auxRecargar);
        
    const refRecargar = useDelta(trafico.recargar);

    const refInit = useDelta(trafico.init);

    const refIdCliente = useDelta(idCliente);

    const dispatchRedux = useDispatch();

     const clientesRedux = useSelector((state) => state.clientes);
       

    useEffect(() => {
        dispatchRedux({ type: "SET_TRAFICO", payload: {...trafico,urlEtiquetas:'',init:1} });
    }, [dispatchRedux, trafico]);


    //GET MENSAJEROS, REDES, ESTADOS Y TIPOS DE SERVICIO.
    useEffect(() => {
        
        if (trafico.init == 0)
        {

            if (TienePermisos([], [usuarios.ver, permisosEnvios.ver_todos, recogidas.ver_todos])) {

                (async () => {

                    var mensajeros = await SelectMensajeros();

                    
                    if (mensajeros.length) 
                    {
                        dispatch({ type: "SET_MENSAJEROS", payload: [{id:"-1",nombre:"Sin mensajero",apellidos:""},...mensajeros] });
                    }
                    else {

                        dispatch({ type: "REMOVE_MENSAJEROS" });
                    }



                })();
            }

            (async () => {

                var estadosTrafico = await SelectEstadosTrafico();

                if (estadosTrafico) {
                    dispatch({ type: "SET_ESTADOS", payload: estadosTrafico })
                }
                else {
                    //Es necesario para pintar la página, si no funciona la api, no continuar.
                }

            })();

            (async () => {
                const ivas = await SelectTiposIVA();
                dispatch({ type: "SET_IMPUESTOS", payload: ivas });
            })();

            
            if (esRolAdministrativo || esMensajero || esCliente) 
            {


                (async () => {


                    if (esRolAdministrativo || esCliente) 
                    {

                        try {

                    
                            const { data } = await getRedes({per_page: -1, activo: 1});

                            dispatch({ type: "SET_REDES", payload: data });
                            
                        }
                        catch(error)
                        {
                            console.error('Error al obtener las redes', error);
                        }
                    }
                    else if (esMensajero)
                    {
                        const { success, respuesta } = await ejecutaAPI('GET', `servicios?per_page=-1`);

                        if (success) 
                        {


                            var arrayServicios = respuesta.data.map(servicio => {


                                return { ...servicio, nombre: `${servicio.nombre} (${servicio.nombreRed})` };

                            })

                            arrayServicios.sort((a, b) => a.idRed.localeCompare(b.idRed));
                        

                            dispatch({ type: "SET_SERVICIOS", payload: arrayServicios });
                        }
                    }


                })();



                (async () => {


                    const serviciosTipo = await SelectServiciosTipo();

                    if (serviciosTipo) {
                        dispatch({ type: 'SET_SERVICIOS_TIPOS', payload: serviciosTipo });
                    }

                })();

                
                if (esRolAdministrativo || esMensajero)
                {
                    (async () => {


                        let provinciasAPI = await SelectProvincias();

                        if (provinciasAPI) {

                            dispatch({ type: 'SET_PROVINCIAS', payload: provinciasAPI });

                        }


                    })();

                    if (TienePermisos([permisosEnvios.crear]))
                    {
                        //1h cache clientes     
                        if (clientesRedux && clientesRedux?.clientesTrafico_time && (Date.now() / 1000) - (clientesRedux?.clientesTrafico_time) / 1000 < 3600) 
                        {
                            dispatch({ type: "SET_CLIENTES", payload: clientesRedux.clientesTrafico });
                        } 
                        else 
                        {

                            (async () => {

                                const { success, respuesta } = await ejecutaAPI('GET', `clientes?per_page=-1&activo=1`);

                                if (success) {
                                    dispatch({ type: "SET_CLIENTES", payload: respuesta.data });
                                    dispatchRedux({ type: "SET_CLIENTES_TRAFICO", payload: respuesta.data });
                                }

                            })();
                        }
                    }

                }

                (async () => {

                    let paisesAPI = await SelectPaises();
    
                    if (paisesAPI) {
    
                        let arrayPaises = [];
    
                        arrayPaises = paisesAPI.map((pais) => {
    
    
                            return { id: pais.id, value: pais.pais, bandera: pais.bandera }
    
                        });
    
                        dispatch({ type: 'SET_PAISES', payload: arrayPaises });
    
                    }
    
    
                })();

            }

            dispatch({ type: "SET_INIT", payload: 1 });
        }                


    }, [dispatch, dispatchRedux, esRolAdministrativo, esMensajero, clientesRedux, trafico.init, esCliente, idCliente]);

    //Se ejecuta al cambiar el cliente.
    useEffect(() => {

        if (refIdCliente !== null) {

            (async () => {

                if (esCliente) {

                    var respuesta = await getDepartamentosCliente(idCliente, { per_page:-1 });

                    if (respuesta.data) 
                    {
                        dispatch({ type: 'SET_DEPARTAMENTOS_CLIENTE', payload: respuesta.data });
                    }


                }

            })();

        }


    }, [idCliente, refIdCliente, esCliente, dispatch]);


    const recargaFiltrosRecargar=useCallback(()=>{

        if (refFiltrosRecargar.distribucionPropia !== null || refFiltrosRecargar.facturado !== null || refFiltrosRecargar.anulado!=null || refFiltrosRecargar?.idMensajero!=null){
            return true;
        }else{
            return false;
        }

    },[refFiltrosRecargar]);
     

    //GET TRÁFICO
    useEffect(() => {

        (async () => {

            var entra=false;

            if ((((refRecargar !== null && (refRecargar.curr === true || refRecargar.curr === "1")) || recargaFiltrosRecargar() ) && ((trafico.init == 2))) || ((refInit !== null && refInit.curr == 2))) {
                entra=true;
            }


            if (entra) {                
                
                //setGlobalCargando(true);

                dispatch({type:"CARGANDO_INIT"});

                var url = `trafico?fechaDesde=${trafico.filtros.fechaDesde}&fechaHasta=${trafico.filtros.fechaHasta}`;

                if (trafico.filtros.distribucionPropia !== "") {
                    url += `&distribucionPropia=${trafico.filtros.distribucionPropia}`
                }

                if (trafico.filtros.facturado!== "") {
                    url += `&facturado=${trafico.filtros.facturado}`
                }

                if (trafico.filtros.anulado !== "") {
                    url += `&anulado=${trafico.filtros.anulado}`
                }

                if (trafico.filtros.categorias !== "")
                {
                    url +=`&categorias=${trafico.filtros.categorias}`;
                }

                if(esMensajero && trafico.filtros.idMensajero>0){
                    url += `&idMensajero=${trafico.filtros.idMensajero}`
                }


                //Filtro externo al llamar al hook.
                if (filtroTrafico !== undefined && filtroTrafico !== "") {
                    url += `&${filtroTrafico}`;
                }

              
                var traficoAPI = await ejecutaAPI('GET', url);

                if (traficoAPI.success) {                    
                    dispatch({ type: "LISTADO_SUCCESS", payload: traficoAPI.respuesta });
                }
                else {

                    //No hay registros.
                    dispatch({ type: "LISTADO_ERROR" });

                }

                dispatch({ type: "CARGANDO_END"});
                //setGlobalCargando(false);                

            }

        })();
   

    }, [trafico.recargar, trafico.filtros.fechaDesde, trafico.filtros.fechaHasta, trafico.filtros.distribucionPropia, trafico.filtros.facturado, trafico.filtros.categorias, trafico.filtros.anulado,trafico.filtros.idMensajero, filtroTrafico, trafico.init, refRecargar, refInit, recargaFiltrosRecargar, dispatch, setGlobalCargando,esMensajero]);


    //CARGAR DELEGACIONES, SERVICIOS, AL CAMBIAR LA RED.

    useEffect(() => {


        if (refIdRed !== null) {

            if (trafico.filtros.idRed) {
                //DELEGACIONES
                (async () => {

                    var existe = trafico.delegaciones.some(delegacion => delegacion.idRed === trafico.filtros.idRed);

                    if (!existe) {

                        const respuesta = await SelectDelegacionesRed(trafico.filtros.idRed);

                        if (respuesta) {
                            dispatch({ type: 'SET_DELEGACIONES', payload: respuesta });
                        }

                    }
                    else {
                        dispatch({ type: 'SET_DELEGACIONES', payload: [] });
                    }

                })();

                //SERVICIOS
                (async () => {

                    var existe = trafico.servicios.some(servicio => servicio.idRed === trafico.filtros.idRed);

                    if (!existe) {

                        const respuesta = await SelectServiciosRed_Cache(trafico.filtros.idRed);

                        if (respuesta) {
                            dispatch({ type: 'SET_SERVICIOS', payload: respuesta });
                        }

                    }
                    else {
                        dispatch({ type: 'SET_SERVICIOS', payload: [] });
                    }

                })();

            }
            else {
                dispatch({ type: 'SET_DELEGACIONES', payload: [] });

                dispatch({ type: 'SET_SERVICIOS', payload: [] });
            }

        }




    }, [dispatch, refIdRed, trafico.servicios, trafico.delegaciones, trafico.filtros.idRed])

    //SACAR DEPARTAMENTOS SEGÚN EL CLIENTE SELECCIONADO

    useEffect(() => {

        if (refIdClienteFiltro !== null) {

            if (trafico.filtros.idCliente) {

                var existe = trafico.departamentos.some(departamento => departamento.idCliente === trafico.filtros.idCliente);

                if (!existe) {

                    (async () => {

                        var respuesta = await getDepartamentosCliente(trafico.filtros.idCliente, { per_page:-1 });

                        if (respuesta.data) {
                            dispatch({ type: 'SET_DEPARTAMENTOS_FILTRO', payload: respuesta.data });
                        }

                    })();
                }
                else {
                    dispatch({ type: 'SET_DEPARTAMENTOS_FILTRO', payload: [] });
                }
            }
            else {
                dispatch({ type: 'SET_DEPARTAMENTOS_FILTRO', payload: [] });
            }

        }

    }, [dispatch, trafico.departamentos, trafico.filtros.idCliente, refIdClienteFiltro])

    return {
        ...trafico,
        dispatch,

    }

}

export const useTraficoContext = () => {

    var [trafico, dispatch] = useContext(ListadoTraficoContext);

    const { setGlobalCargando } = useGlobalLoader();

    const { datosUser } = useUsuarioConectado();

    const {mutateAsync} = useRecalcularComisiones();

    const {mutate: mutateParcialEnvio} = useApiModificacionParcialEnvio();

    const { mutate: mutateGenerarXML } = useApiGenerarXML();

    const { valorarEnvios, actualizaValoracionesEnvios, calculaValoraciones } = FuncionesValoraciones(null, 0, () => { }, () => { }, () => { }, trafico.impuestos);

    const { esRolAdministrativo } = useRoles();

    const timerRef = useRef(null);


    const generarXMLTrafico = useCallback(() => {

        setGlobalCargando(true);

        const idsEnvios = trafico.listadoMostrar.filter(trafico => trafico.tipo === "envio").map(trafico => trafico.id);

        if (idsEnvios.length > 0)
        {

            const datosEnviar = {
                envios: idsEnvios
            }

            mutateGenerarXML(datosEnviar, {
                onSuccess: (data) => {
                    
                    const blob = new Blob([data], { type: 'application/xml' });

                    const url = URL.createObjectURL(blob);

                    const link = document.createElement('a');

                    let timestampActual = new Date().getTime();

                    link.href = url;
                    link.download = `envios-${timestampActual}.xml`;

                    link.click();

                    setTimeout(() => URL.revokeObjectURL(url), 7000);

                },
                onError: (error) => swalError("Error", "Se ha producido un error al generar el XML"),
                onSettled: () => setGlobalCargando(false)
            });
        }
        else 
        {
            swalError("Error", "No se han filtrado envíos");
        }

    }, [mutateGenerarXML, setGlobalCargando, trafico.listadoMostrar])

    const handleSetMensajero = async (nuevoValor, objeto) => {

        setGlobalCargando(true);

        var url = `recogidas/${objeto.id}`;

        if (objeto.tipo === "envio") {
            url = `envios/${objeto.id}`;
        }

        var respuesta = await ejecutaAPI('PUT', url, { idMensajero: nuevoValor || null });

        setGlobalCargando(false);

        if (respuesta.success) {

            //dispatch({ type: 'RECARGAR_LISTADO', payload: true });
            dispatch({ type: 'RECARGAR_MENSAJERO', payload: { idMensajero: nuevoValor, idUnico: objeto.idUnico } });

        }

    }

    const handleSetTerminado = async (objeto) => {

        if (objeto.tipo.indexOf("recogida")>=0) {

            setGlobalCargando(true);

            var url = `recogidas/${objeto.id}/estados`;

            var datosEnviar = {
                estado: "terminado"
            }

            var respuesta = await ejecutaAPI('POST', url, datosEnviar);

            setGlobalCargando(false);

            if (respuesta.success) {

                //dispatch({ type: 'RECARGAR_LISTADO', payload: true });
                var estado = { id: "1", idEstado: "5", idUsuario: datosUser.user.id, observaciones: null, fecha: moment().format("DD/MM/YYYY"), codigo: 'terminado', estado: 'Terminado', icono: 'done_outline', usuario: datosUser.user.user };

                dispatch({ type: 'RECARGAR_ESTADO', payload: { estado: estado, idUnico: objeto.idUnico } });

            }
        }
    }

    const handleAccionMasiva = (e) => {


        dispatch({ type: 'SET_ACCION_MASIVA_SOBRE', payload: e.target.value });

    }

    const handleSeleccionarElemento = (e) => {


        dispatch({ type: 'HANDLE_ELEMENTOS_SELECCIONADOS', payload: { id: e.target.value, checked: e.target.checked } });


    }

    const handleSeleccionarTodosElementos = (e) => {

        e.stopPropagation();

        dispatch({ type: 'HANDLE_SELECCIONAR_TODOS_ELEMENTOS' });

    }

    const handleAccionSeleccionada = (e) => {

        dispatch({ type: 'SET_ACCION_SELECCIONADA', payload: e.target.value });

    }

    const handleSetVista = (vista) => {

        dispatch({ type: 'SET_VISTA', payload: vista });
        localStorage.setItem("vistaTrafico", vista);
    }


    const handleSetOrden = (orden) => {        
        dispatch({ type: 'SET_FILTROS', payload: {...trafico.filtros,orden:orden} });        
    }
  

    const resetFiltros = () => {
        localStorage.removeItem("filtrosTrafico");
        localStorage.removeItem("fecha_filtrosTrafico");
        dispatch({ type: 'SET_FILTROS', payload: trafico.filtrosInit });
        dispatch({ type: 'RECARGAR_LISTADO', payload: true });

    }


    const handleSetComprobado = async (objeto) => {

        const enviarEstadoComprobado = async () => {

            setGlobalCargando(true);

            var url = `recogidas/${objeto.id}/estados`;

            var datosEnviar = {
                estado: "comprobado"
            }

            var respuesta = await ejecutaAPI('POST', url, datosEnviar);

            setGlobalCargando(false);

            if (respuesta.success) {

                //dispatch({ type: 'RECARGAR_LISTADO', payload: true });
                var estado = { id: "1", idEstado: "3", idUsuario: datosUser.user.id, observaciones: null, fecha: moment().format("DD/MM/YYYY"), codigo: 'comprobado', estado: 'Comprobado', icono: 'verified', usuario: datosUser.user.user };

                dispatch({ type: 'RECARGAR_ESTADO', payload: { estado: estado, idUnico: objeto.idUnico } });

            }
            else {
                Swal.fire({
                    title: "Error",
                    text: 'Se ha producido un error',
                    icon: 'error'
                });
            }
        }

        if (objeto.tipo === "recogida") {

            setGlobalCargando(true);

            var datosRecogidaAPI = await ejecutaAPI('GET', `recogidas/${objeto.id}`);

            if (datosRecogidaAPI.success) {
                var datosRecogida = datosRecogidaAPI.respuesta;



                if (datosRecogida.notasMensajero) {
                    setGlobalCargando(false);

                    Swal.fire({
                        title: 'Aviso',
                        html: `La recogida tiene la siguiente nota: <br> ${datosRecogida.notasMensajero}`,
                        icon: 'info',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                    }).then(async (result) => {

                        if (result.value) {

                            enviarEstadoComprobado();

                        }
                    });
                }
                else {
                    enviarEstadoComprobado();
                }


            }
            else {
                Swal.fire({
                    title: "Error",
                    text: 'Se ha producido un error',
                    icon: 'error'
                });
            }
        }
    }

    const handleDesplegarFiltros = () => {

        dispatch({ type: "CHANGE_DESPLEGAR_FILTROS" });

    }

    const imprimirEtiquetas = useCallback(async (formato) => {

        setGlobalCargando(true);

        const envios = trafico.listadoMostrar.reduce((filtrado, trafico) => {

            if (trafico.tipo === "envio") 
            {
                filtrado.push(trafico.id);
            }

            return filtrado;
        }, []);

        if (envios.length > 0) 
        {

            const data = { "envios": envios, "formato": formato };

            const { success, respuesta } = await ejecutaAPI('POST', `envios/etiquetas`, data);

            if (success) {

                if (respuesta.PDFEtiquetasBase64) {

                    var blob = base64ToBlob(respuesta.PDFEtiquetasBase64, "application/pdf");

                    var blobUrl = URL.createObjectURL(blob);

                    dispatch({ type: 'SET_URL_ETIQUETAS', payload: blobUrl });
                }

            }
            else 
            {

                var error = respuesta.data?.msg?.join("<br>");

                if (!error) {
                    error = "Se ha producido un error obteniendo las etiquetas";
                }

                Swal.fire({
                    title: 'Error',
                    text: error,
                    icon: 'error'
                });
            }
        }
        else 
        {
            Swal.fire({
                title: 'Error',
                text: 'No se han filtrado envíos',
                icon: 'error'
            });
        }

        setGlobalCargando(false);

    }, [trafico.listadoMostrar, setGlobalCargando, dispatch]);

    const imprimirCodBarras = useCallback(async () => {

        setGlobalCargando(true);

        const idsEnvios = trafico.listadoMostrar.filter(trafico => trafico.tipo === "envio").map(trafico => trafico.id);

        if (idsEnvios.length > 0) 
        {

            const data = { "envios" : idsEnvios };

            const { success, respuesta } = await ejecutaAPI('POST', `envios/codigosbarra`, data);

            if (success) 
            {

                if (respuesta.PDFEtiquetasBase64) {

                    var blob = base64ToBlob(respuesta.PDFEtiquetasBase64, "application/pdf");

                    var blobUrl = URL.createObjectURL(blob);

                    dispatch({ type: 'SET_URL_ETIQUETAS', payload: blobUrl });
                }

            }
            else 
            {

                var error = respuesta.data?.msg?.join("<br>") ?? "Se ha producido un error obteniendo las etiquetas";

                Swal.fire({
                    title: 'Error',
                    text: error,
                    icon: 'error'
                });
            }
        }
        else 
        {
            Swal.fire({
                title: 'Error',
                text: 'No se han filtrado envíos',
                icon: 'error'
            });
        }

        setGlobalCargando(false);

    }, [trafico.listadoMostrar, setGlobalCargando, dispatch])

    const generaLecturas = useCallback(async (tipoFichero) => {

        setGlobalCargando(true);

        if (!(trafico.filtros.idRed > 0)) 
        {

            Swal.fire({
                title: '',
                text: 'Debes de seleccionar una red',
                icon: 'warning'
            });

        } 
        else 
        {

            const envios = trafico.listadoMostrar.filter(i => i.tipo == "envio");

            const objRed = trafico.redes.find(i => i.idRed === trafico.filtros.idRed);

            try {

                let datos = {
                    envios: envios,
                    red: objRed,
                    filtros: trafico.filtros
                }
                
                let ficheroLecturas = FicheroLecturasFactory.create(datos, tipoFichero);

                ficheroLecturas.descargar();

                

            }
            catch(error)
            {
                Swal.fire({
                    title: 'Error',
                    text: error.message,
                    icon: 'error'
                });
            }

        }

        setGlobalCargando(false);

    }, [trafico.filtros, trafico.listadoMostrar, trafico.redes, setGlobalCargando])

    const handleValoracionEnvios = useCallback(() => {

        const listadoMostrar = trafico.listadoMostrar

        if (esRolAdministrativo) {

            var enviosValorar = listadoMostrar.filter(i => i.tipo == "envio" && i.valoracionManual == 0);

            const numFacturados = listadoMostrar.filter(i => i.tipo == "envio" && i.valoracionManual == 0 && i.facturado == 1);

            const enviosRecogidasValorarComisiones = listadoMostrar.filter(i => i.comisionManual === "0");

            var fact = "";

            if (numFacturados.length > 0)
            {
                fact = `<br>Envios facturados: ${numFacturados.length}`;
            }

            if (enviosRecogidasValorarComisiones.length > 0)
            {
                fact += `<br/> <br/> Se revalorarán las comisiones de ${enviosRecogidasValorarComisiones.length} envios y recogidas`;
            }

            Swal.fire({
                title: 'Valorar envios',
                html: `Se van a valorar ${enviosValorar.length} envios ${fact}`,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: `Aceptar`,
                denyButtonText: `Cancelar`

            }).then(res => {

                if (res.isConfirmed) {

                    setGlobalCargando(true);

                    valorarEnvios(enviosValorar, esRolAdministrativo).then(async (datosValoraciones) => {

                        if (datosValoraciones.valoraciones_envios.length > 0 || enviosRecogidasValorarComisiones.length > 0) 
                        {

                            //var resChunk = chunk(res, 1000);

                            new Promise(async function (resolve, reject) {

                                var promiseVal = [];
                                var resPromise = [];
                                
                                if (datosValoraciones.valoraciones_envios.length > 0)
                                {

                                    //resChunk.forEach(async (resEnvios) => {
                                        promiseVal.push(actualizaValoracionesEnvios(datosValoraciones.valoraciones_envios));
                                    //});

                                    resPromise = await Promise.all(promiseVal);

                                }
                                
                                resolve(resPromise);
                                                             

                            }).then(async res => {

                                var valorado = 0;

                                res.forEach(item => valorado += item.length);
                                
                                //Ahora mandamos a revalorar comisiones
                                let textoAdicional = "";

                                let comisionesRecalculadas = false;

                                try {

                                    var comisionesMandar = setObjetoParaObtenerDatosComisiones(enviosRecogidasValorarComisiones);

                                    if (comisionesMandar.length > 0)
                                    {

                                        const datosComisiones = await getDatosComisiones(comisionesMandar);

                                        let arrayActualizar = [];

                                        enviosRecogidasValorarComisiones.forEach(envioRecogidaValorar => {

                                            let tipoBuscar = envioRecogidaValorar.tipo === "envio" ? "envio" : "recogida";

                                            let datosElemento = datosComisiones.datos.find(dato => dato.tipo === tipoBuscar && dato.id === envioRecogidaValorar.id)

                                            if (datosElemento)
                                            {
                                                //Calculamos la comisión del comercial
                                                let valoracion = parseFloat(datosElemento.valoracion || 0);
                                                
                                                let comisionComercial = calcularComisionDesdePorcentaje(valoracion, parseFloat(datosElemento.porcComisionComercial || 0));
                                                let comisionMensajero = calcularComisionDesdePorcentaje(valoracion, parseFloat(datosElemento.porcComisionMensajero || 0));

                                                if (datosElemento.idTarifa)
                                                {
                                                    let datosTarifa = datosComisiones.tarifas?.find(tarifa => tarifa.id === datosElemento.idTarifa);
                                                    //La tarifa que devuelve el API corresponde a la red del envío, por lo que no hay que hacer comprobaciones adicionales
                                                    if (datosTarifa)
                                                    {
                                                        //Localizamos los datos del envío calculados previamente para valorar
                                                        let datosEnvioValoracion = datosValoraciones.infoParaValoracion.find(envio => envio.idEnvio === datosElemento.id);
                                                        
                                                        if (datosEnvioValoracion)
                                                        {
                                                            let valoracionTarifaMensajero = calculaValoraciones([], datosEnvioValoracion.referencias, datosEnvioValoracion.redes, datosEnvioValoracion.idRed, datosEnvioValoracion.idServicio, datosEnvioValoracion.idRuta, datosEnvioValoracion.portes, datosEnvioValoracion.variables, [], 0, datosTarifa);

                                                            let totalValoracionTarifaMensajero = calculaTotalValoraciones(valoracionTarifaMensajero.valoraciones_new);

                                                            comisionMensajero = (parseFloat(comisionMensajero) + parseFloat(totalValoracionTarifaMensajero.precio_total)).toFixed(2);
                                                        }

                                                    }

                                                }

                                                arrayActualizar.push({
                                                    id: datosElemento.id,
                                                    comisionComercial,
                                                    comisionMensajero,
                                                    idComercial: datosElemento.idComercial,
                                                    tipo: datosElemento.tipo
                                                })

                                            }
                                        })


                                        const resultadoComisiones = await mutateAsync(arrayActualizar);

                                        if (resultadoComisiones?.recogidas > 0 || resultadoComisiones?.envios > 0)
                                        {
                                            textoAdicional += `<br>Comisiones de recogidas recalculadas: ${resultadoComisiones.recogidas}`;

                                            textoAdicional += `<br>Comisiones de envios recalculadas: ${resultadoComisiones.envios}`;

                                            comisionesRecalculadas = true;
                                        }
                                    }
                                }
                                catch (exception)
                                {
                                    textoAdicional += `<br> Se ha producido un error al recalcular las comisiones`;
                                }

                                setGlobalCargando(false);


                                if (valorado > 0 || comisionesRecalculadas) 
                                {

                                    
                                    if (numFacturados.length > 0)
                                    {
                                        textoAdicional += `<br><br>Envios facturados: ${numFacturados.length} - REVISAR FACTURAS`;
                                    }

                                    Swal.fire({
                                        title: 'Envios valorados',
                                        html: `Se han valorado ${valorado} envios ${textoAdicional} `,
                                        icon: 'success'

                                    });

                                    dispatch({ type: 'RECARGAR_LISTADO', payload: "1" });


                                } 
                                else 
                                {

                                    Swal.fire(
                                        'Error',
                                        'No se ha valorado ningún envio',
                                        'error'
                                    );
                                }


                            });


                        }
                        else 
                        {
                            setGlobalCargando(false);

                            Swal.fire(
                                'Error',
                                'No se ha valorado ningún envio',
                                'error'
                            );
                        }

                    });



                }

            });

        }


    }, [trafico.listadoMostrar, esRolAdministrativo, setGlobalCargando, valorarEnvios, actualizaValoracionesEnvios, mutateAsync, calculaValoraciones, dispatch])

    const handleCambioParcial = useCallback((datos) => {


        let datosActuales = trafico.listadoMostrar.find(item => item.id === datos.id && item.tipo === "envio");
        
        let propsActualizar = transformaDatosParcialesEnvioParaTrafico(datos, datosActuales);

        let datosActualizar = {
            id: datos.id,
            actualizar: propsActualizar
        }

        dispatch({ type: 'ACTUALIZAR_ESTADO_TRAFICO', payload: datosActualizar});

        let datosEnviar = EnvioParcialMapper.toRequest(datos);

        mutateParcialEnvio(datosEnviar, {
            onSuccess: () => {

                if (timerRef.current) 
                {
                    clearTimeout(timerRef.current);
                }

                timerRef.current = setTimeout(() => {
                    dispatch({ type: 'RECARGAR_LISTADO', payload: "1" });
                }, 5000)
                

            },
            onError: () => {

                datosActualizar = {
                    id: datos.id,
                    actualizar: datosActuales
                }

                dispatch({ type: 'ACTUALIZAR_ESTADO_TRAFICO', payload: datosActualizar});
            }
        });

    }, [dispatch, mutateParcialEnvio, trafico])

    return {
        ...trafico,
        dispatch,
        imprimirEtiquetas,
        imprimirCodBarras,
        generaLecturas,
        generarXMLTrafico,
        handleSetMensajero,
        handleSetTerminado,
        handleAccionMasiva,
        handleSeleccionarElemento,
        handleSeleccionarTodosElementos,
        handleAccionSeleccionada,
        handleDesplegarFiltros,
        handleSetComprobado,
        handleSetVista,
        handleSetOrden,
        handleValoracionEnvios,
        resetFiltros,
        handleCambioParcial
    }
}


/* Estadisticas de envios/recogidas */
export const useEstadisticas = () => {

    const [trafico] = useContext(ListadoTraficoContext);


    /* Devuelve la provincia correspondiente */
    const devuelveProvincia = useCallback((cp) => {

        //Cogemos los dos digitos del cp
        var codigo = cp.substring(0, 2);

        var provincia = trafico.provincias.filter(i => i.cp === codigo);

        if (provincia.length == 1) {
            return provincia[0];
        } else {
            return false;
        }

    }, [trafico.provincias]);



    /* Agrupa el array por un tipo de campo */
    const agruparDatos = useCallback((campo) => {

        var arr = trafico.listadoMostrar;

        var agrupado = [];

        var vacio = { 
            num_envios: 0,
            num_bultos: 0,
            porcentaje_envios: "", 
            num_recogidas: 0, 
            reembolsos: 0, 
            coste: 0, 
            beneficio: 0, 
            margen: 0, 
            coste_real: 0, 
            beneficio_real: 0, 
            margen_real: 0, 
            importe: 0, 
            comisiones: 0, 
            comisiones_mensajero: 0, 
            comisiones_comercial: 0, 
            servicio: "",
            cliente: "", 
            provincia: ""
        };
        var datos = { agrupados: [], agrupadosTotales: [], totales: {}, vacio };

        //Primero agrupamos los datos segun el campo
        switch (campo) {
            case "fecha":
            case "idServicio":
            case "idCliente":
            case "tipo":
                arr.forEach(i => {
                    if (agrupado[i[campo]] === undefined && i[campo]!="recogidaFueraPlaza") {
                        agrupado[i[campo]] = [];
                        agrupado[i[campo]].push(i);
                    } else {
                        if(i[campo]=="recogidaFueraPlaza"){
                            
                            if(agrupado["recogida"]===undefined)        
                               agrupado["recogida"]=[];

                            agrupado["recogida"].push(i);
                        }else{
                            agrupado[i[campo]].push(i);
                        }
                    }
                }
                );
                break;
            case "provincia":
                arr.forEach(i => {

                    if (i["tipo"] == "envio") {
                        let provincia = devuelveProvincia(i.direccion.cp)
                        if (provincia) {
                            if (agrupado[provincia.id] === undefined) {
                                agrupado[provincia.id] = [];
                                agrupado[provincia.id].push(i);
                            } else {
                                agrupado[provincia.id].push(i);
                            }
                        }
                    }
                }
                );
                break;


            default:
                agrupado = arr;
                break;

        }

        let arr_aux = toPairs(agrupado);

        let aux = [];

        arr_aux.forEach(i => {
            if (i[1] !== undefined && i[0] !== null && i[0] != "null")
                aux.push(i);
        })


        //Ordenamos el array si son fechas.
        if (campo == "fecha") {

            aux.sort(function (a, b) {

                let arr = a[0].split("/");
                let fecha1 = moment(arr[2] + "-" + arr[1] + "-" + arr[0]);
                arr = b[0].split("/");
                let fecha2 = moment(arr[2] + "-" + arr[1] + "-" + arr[0]);

                if (fecha1 > fecha2) {
                    return 1
                } else if (fecha1 < fecha2) {
                    return -1;
                } else {
                    return 0;
                }
            });

        }

        datos.agrupados = aux;
        datos.agrupadosTotales = [];

        //Calculamos los totales de los agrupados

        datos.agrupados && datos.agrupados.forEach(i => {

            let obj = { 
                num_envios: 0, 
                num_bultos: 0,
                porcentaje_envios: "", 
                num_recogidas: 0, 
                reembolsos: 0, 
                coste: 0, 
                beneficio: 0, 
                margen: 0, 
                coste_real: 0, 
                beneficio_real: 0,
                margen_real: 0, 
                importe: 0, 
                comisiones: 0, 
                comisiones_mensajero: 0, 
                comisiones_comercial: 0, 
                servicio: "", 
                cliente: "", 
                provincia: ""
            };

            let enviosGrupo = i[1].filter(item => item.tipo === "envio");

            obj.num_envios = enviosGrupo.length;
            obj.num_recogidas = i[1].filter(item => (item.tipo === "recogida" || item.tipo==="recogidaFueraPlaza")).length;

            if (campo === "idServicio")
                obj.servicio = i[1][0].nombreServicio;

            if (campo === "idCliente")
                obj.cliente = i[1][0].cliente;

            if (campo === "provincia") {
                obj.provincia = trafico.provincias.find(item => item.id == i[0]) && trafico.provincias.find(item => item.id == i[0]).provincia;
            }


            i[1].forEach(item => {

                if (item.valoraciones.length > 0) 
                {

                    var val_coste = sumaColumnaArray(item.valoraciones, "coste");
                    var val_precio = sumaColumnaArray(item.valoraciones, "precio");

                    if (!isNaN(val_coste))
                    {
                        obj.coste = obj.coste + val_coste;
                    }

                    if (!isNaN(val_precio))
                    {
                        obj.importe = obj.importe + val_precio;
                    }

                    //Comisiónes

                    if (item.idMensajero)
                    {
                        obj.comisiones_mensajero += parseFloat(item.comisionMensajero);
                    }

                    if (item.idComercial)
                    {
                        obj.comisiones_comercial += parseFloat(item.comisionComercial);
                    }

                }


                if (item.contraReembolso > 0 && !isNaN(item.contraReembolso)) {
                    obj.reembolsos = obj.reembolsos + parseFloat(item.contraReembolso);
                }

                if (item.importeCostesReales > 0 && !isNaN(item.importeCostesReales)) {
                    obj.coste_real = obj.coste_real + parseFloat(item.importeCostesReales);
                }

                if (item.tipo === "envio") {
                    obj.num_bultos += parseInt(item.bultos);
                }


            });

            obj.comisiones = obj.comisiones_comercial + obj.comisiones_mensajero;

            if (obj.coste > 0 || obj.importe > 0) {
                obj.beneficio = obj.importe - obj.coste;
                obj.margen = ((obj.beneficio / obj.importe) * 100);
            }

            if ((parseFloat(obj.coste_real) > 0 || parseFloat(obj.comisiones) > 0) && parseFloat(obj.importe) > 0) {
                obj.beneficio_real = obj.importe - obj.coste_real - obj.comisiones;
                obj.margen_real = ((obj.beneficio_real / obj.importe) * 100);
            } else {
                obj.beneficio_real = obj.importe;
                obj.margen_real = 100;
            }

            let aux = [];
            aux[0] = i[0];
            aux[1] = obj;

            datos.agrupadosTotales.push(aux);

        });

        //Ordenamos por numero de envios
        if (campo !== "fecha") {
            datos.agrupadosTotales = datos.agrupadosTotales.sort(function (a, b) {

                let dato1 = a[1].num_envios;
                let dato2 = b[1].num_envios;

                if (dato1 > dato2) {
                    return -1
                } else if (dato1 < dato2) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }


        //Total de todo
        let obj = { 
            num_envios: 0,
            num_bultos: 0,
            num_recogidas: 0, 
            reembolsos: 0, 
            coste: 0, 
            beneficio: 0, 
            margen: 0, 
            coste_real: 0, 
            beneficio_real: 0, 
            margen_real: 0, 
            importe: 0, 
            comisiones: 0, 
            comisiones_mensajero: 0, 
            comisiones_comercial: 0 
        };



        arr.forEach(item => {

            if (item.tipo === "envio") {
                obj.num_envios++;
                obj.num_bultos += parseInt(item.bultos);
            }

            if (item.tipo.indexOf("recogida")>=0) {
                obj.num_recogidas++;
            }


            if (item.valoraciones.length > 0) {

                var val_coste = sumaColumnaArray(item.valoraciones, "coste");
                var val_precio = sumaColumnaArray(item.valoraciones, "precio");

                if (!isNaN(val_coste))
                {
                    obj.coste = obj.coste + val_coste;
                }

                if (!isNaN(val_precio))
                {
                    obj.importe = obj.importe + val_precio;
                }

                //Comisiónes
                if (item.idMensajero)
                {
                    obj.comisiones_mensajero += parseFloat(item.comisionMensajero);
                }

                if (item.idComercial)
                {
                    obj.comisiones_comercial += parseFloat(item.comisionComercial);
                }

            }

            if (item.contraReembolso > 0 && !isNaN(item.contraReembolso)) {
                obj.reembolsos = obj.reembolsos + parseFloat(item.contraReembolso);
            }

            if (item.importeCostesReales > 0 && !isNaN(item.importeCostesReales)) {
                obj.coste_real = obj.coste_real + parseFloat(item.importeCostesReales);
            }

        });

        obj.comisiones = obj.comisiones_comercial + obj.comisiones_mensajero;

        if (obj.coste > 0 || obj.importe > 0) {
            obj.beneficio = obj.importe - obj.coste;
            obj.margen = ((obj.beneficio / obj.importe) * 100);
        }

        if ((parseFloat(obj.coste_real) > 0 || parseFloat(obj.comisiones) > 0) && parseFloat(obj.importe) > 0) {
            obj.beneficio_real = obj.importe - obj.coste_real - obj.comisiones;
            obj.margen_real = ((obj.beneficio_real / obj.importe) * 100);
        } else {
            obj.beneficio_real = obj.importe;
            obj.margen_real = 100;



        }


        datos.totales = obj;


        //Hacemos el recuento de envios porcentaje.

        datos.agrupadosTotales = datos.agrupadosTotales.map(i => {

            i[1].num_envios_porcentaje = '0 (0,00%)';

            if (datos.totales.num_envios > 0) {

                let porcentaje = (i[1].num_envios * 100) / datos.totales.num_envios;

                i[1].num_envios_porcentaje = i[1].num_envios + '  (' + parseFloat(porcentaje).toFixed(2) + " %)";

            }

            if (datos.totales.num_bultos > 0) {

                let porcentaje = (i[1].num_bultos * 100) / datos.totales.num_bultos;

                i[1].num_bultos_porcentaje = i[1].num_bultos + '  (' + parseFloat(porcentaje).toFixed(2) + " %)";

            }

            return i;

        })



        return datos;


    }, [trafico.listadoMostrar, trafico.provincias, devuelveProvincia]);



    return {
        ...trafico,
        agruparDatos
    }

}

